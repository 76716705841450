.product-device-list {
  table{
    &.table{
      tr{
        td:nth-child(3) {
          max-width: 400px;
        }
      }
      td{
        font-size: 13.5px;
        word-break: break-all;
      }
    }
  }
}