.product-device-count{
  .card{
    width: 100%;
  }
  .col{
    flex: 1 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .pl-widget{
    border: 1px;
    border-radius: 10px;
    margin: 0;
    padding: 5px;
    display: flex;
    width: 16.66%;
    font-size: 12px;
    strong {
      font-size: 13px;
    }
    .img{
      float: left;
      img{
        height: 70px;
        width: 70px;
      }
    }
    .content{
      float: left;
      .count{
        font-size: 16px;
      }
    }
  }
}